<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <h1 class="header_name">Assign Goals</h1>
    </div>
    <img class="close-icon-svg" (click)="closeDialog()" 
    src="assets/icons/dashboard-icons/close-pink-icon.svg" />
  </div>
  <hr />
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="container">
      <div class="row">
        <div
          class="col-md-4 col-sm-6 goal_box_width"
          *ngFor="let goal of Goal; let i = index"
        >
          <div class="custom-checkbox">
            <label class="goal-heading">
              <input
                type="checkbox"
                class="blue-box-size"
                [disabled]="goal.isDisable"
                [checked]="goal.check"
                [value]="goal.value"
                (change)="onBoxChange($event)"
              />
              <span class="checkmark"></span>
              {{ goal.name }}
            </label>

            <div class="whiteBox">
              <div class="descriptor">Descriptor</div>
              <div class="discContent-li">
                <div
                  class="descContent"
                  *ngFor="let descriptor of goal.description"
                >
                  <li>{{ descriptor }}</li>
                </div>
              </div>

              <div class="successCriteria">
                Success Criteria and Measurement
              </div>
              <div class="successDesc-li">
                <div class="successDesc" *ngFor="let success of goal.success">
                  <li>{{ success }}</li>
                </div>
              </div>
            </div>
            <div class="blueBox">
              <dev class="blueCare"> Blue care </dev>
              <dev class="BlueDesc">
                {{ goal.blue }}
              </dev>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="submit_button_main">
          <button class="blue-button" type="submit">
            <span class="add_em_name">Assign</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
