<mat-card class="main-card" [style.height.px]="hasCmRights ? 483 : 731">

  <div class="row">
    <div class="col-md-12">
      <div class="main-header">
        <div class="svg-heading-div">
          <img class="submodule-svg" src="assets/icons/dashboard-icons/action-items.svg" />
          <div class="head">Action Items</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="tableFixHead">
        <!-- <div class="single-balance-container" (click)="taskListForSingleModule('Leave Management')">
          <div class="svg-container lms-icon-bg">
            <img src="assets/icons/dashboard-icons/blue-calendar.svg">
          </div>
          <div class="leave-type">Leave Management</div>
          <div class="leave-balance">
            <div class="num ba-color">
              {{ lmsTasksCount$ | async | number : "2.0-0" }}
            </div>
          </div>
        </div> -->

        <!-- <div class="single-balance-container" (click)="taskListForSingleModule('WFO')">
          <div class="svg-container wfo-icon-bg">
            <img src="assets/icons/dashboard-icons/wfo-task-icon.svg">
          </div>
          <div class="leave-type">WFO</div>
          <div class="leave-balance">
            <div class="num ba-color">
              {{ wfoTasksCount$ | async | number : "2.0-0" }}
            </div>
          </div>
        </div> -->

        <div class="single-balance-container" (click)="taskListForSingleModule('Performance')">
          <div class="svg-container performance-icon-bg">
            <img src="assets/icons/dashboard-icons/performance-icon.svg">
          </div>
          <div class="leave-type">Performance</div>
          <div class="leave-balance">
            <div class="num yellow-color">
              {{ performanceTasksCount$ | async | number : "2.0-0" }}
            </div>
          </div>
        </div>

        <div class="single-balance-container" (click)="taskListForSingleModule('Profile')">
          <div class="svg-container profile-icon-bg">
            <img src="assets/icons/dashboard-icons/pink-profile-icon.svg">
          </div>
          <div class="leave-type">Profile</div>
          <div class="leave-balance">
            <div class="num pink-color">
              {{ profileTasksCount$ | async | number : "2.0-0" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>