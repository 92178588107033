import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { Departments, ExcelSheetName, GeoLocation, JobTitles } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { DeactivateEmployeeComponent } from '../../employee/employee-activation/deactivate-employee/deactivate-employee.component';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { AddNewEmployeeCardComponent } from '../add-new-employee-card/add-new-employee-card.component';
import { TooltipPosition } from '@angular/material/tooltip';
import { formatDate } from '@angular/common';
import { ExportEmployeeDetailsComponent } from '../../employee/export-employee-details/export-employee-details.component';

@Component({
  selector: 'app-active-employees',
  templateUrl: './active-employees.component.html',
  styleUrls: ['./active-employees.component.scss']
})
export class ActiveEmployeesComponent implements OnInit {
  activeUser: UserDTO[]
  departments = [];
  jobTitles = [];
  geoLocationEnum = [];
  department = Departments;
  jobTitle = JobTitles;
  page: number = 1;
  isSpinner: boolean = true;
  searchTerm: string = '';
  filteredUsers: UserDTO[];
  searchForm: FormGroup;
  capabilitySearch: Array<string> = [];
  jobTitleSearch: Array<string> = [];
  geoLocation: Array<string> = [];
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  currentSortName: { column: string, direction: 'asc' | 'desc' } = { column: 'name', direction: 'asc' };
  currentSortEmployeeId: { column: string, direction: 'asc' | 'desc' } = { column: 'employeeId', direction: 'asc' };
  currentSortJobTitle: { column: string, direction: 'asc' | 'desc' } = { column: 'jobTitle', direction: 'asc' };
  currentSortDepartment: { column: string, direction: 'asc' | 'desc' } = { column: 'department', direction: 'asc' };

  // Add this property to your component class
  currentSort: { column: string, direction: 'asc' | 'desc' } = { column: 'name', direction: 'asc' };
  constructor(private userService: UserService, private dialog: MatDialog, private router: Router
  ) {

  }
  ngOnInit(): void {
    this.processEnumValuesToArray();
    this.getActiveUser();
  }
  getActiveUser() {
    this.isSpinner = true;
    this.userService.getActiveEmployee().subscribe(res => {

      this.filteredUsers = res;
      this.activeUser = res;
      this.filteredUsers.forEach(element => {
        if (element.userImageThumbnail !== '') {
          element.userImageThumbnail =
            'data:image/png;base64,' + element.userImageThumbnail;
        }
        this.userService.getEmployeeById(element.careerManagerId).subscribe(res => {
          element.carrierManagerName = res.body.firstName + " " + res.body.lastName;
        })
      });
      this.isSpinner = false;
    }), (e) => {
      this.isSpinner = false;
    };
  }
  
  ifOverflow(e) {
    return e.scrollWidth > e.clientWidth;
  }
  editEmployeeDialog(empData) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = '75%';
    dialogConfig.data = [empData, "active-user"];

    const dialogRef = this.dialog.open(AddNewEmployeeCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.getActiveUser();
      this.searchTerm = '';
      this.capabilitySearch = [];
      this.jobTitleSearch = [];
      this.geoLocation = [];
    });
  }
  deactivateEmployee(empData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.height = '200px';
    dialogConfig.data = [empData, "Deactivate"];
    dialogConfig.panelClass = 'deactivate-employee';
    const dialogRef = this.dialog.open(
      DeactivateEmployeeComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.getActiveUser();
    });
  }
  viewEmployee(empDetail) {
    this.router.navigate([`/employee/employee-profile/${empDetail.id}`]);
  }
  applySearch() {
    this.capabilitySearch = [];
    this.jobTitleSearch = [];
    this.geoLocation = [];
    let filteredUsers = this.activeUser.filter(user => {
      const nameMatch = user.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      const carrierManagerMatch =
        (user.carrierManagerName?.toLowerCase() ?? '').includes(this.searchTerm.toLowerCase());

      return nameMatch || carrierManagerMatch;
    });

    filteredUsers.sort((a, b) => {
      const aNameMatch = a.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      const bNameMatch = b.name.toLowerCase().includes(this.searchTerm.toLowerCase());

      if (aNameMatch && !bNameMatch) {
        return -1;
      } else if (!aNameMatch && bNameMatch) {
        return 1;
      } else {
        return 0;
      }
    });
    this.filteredUsers = filteredUsers;
  }
  applySearchForJobTitleAndDepartement() {
    let filteredUsers = this.activeUser.filter(user => {

      const departmentKeys = this.capabilitySearch.map(capability => this.getDepartmentKeyByValue(capability));
      const jobTitleKeys = this.jobTitleSearch.map(jobTitle => this.getJobTitleKeyByValue(jobTitle));
      const geoLocationKeys = this.geoLocation.map(geoLocation => this.getGeoLocationKeyByValue(geoLocation));

      const departmentMatch = departmentKeys.length === 0 || departmentKeys.includes(user.department);
      const jobTitleMatch = jobTitleKeys.length === 0 || jobTitleKeys.includes(user.jobTitle);
      const geoLocationMatch = geoLocationKeys.length === 0 || geoLocationKeys.includes(user.geoLocation);
      return departmentMatch && jobTitleMatch && geoLocationMatch;
    });
    this.filteredUsers = filteredUsers;
  }
  processEnumValuesToArray() {
    for (const jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle))) {
        this.jobTitles.push(jobTitle);
      }
    }

    for (const department of Object.values(Departments)) {
      if (isNaN(Number(department))) {
        this.departments.push(department);
      }
    }
    for (const department of Object.values(GeoLocation)) {
      if (isNaN(Number(department))) {
        this.geoLocationEnum.push(department);
      }
    }
  }
  getDepartmentKeyByValue(value: string): string | undefined {
    return Object.keys(Departments).find(key => Departments[key] === value);
  }
  getJobTitleKeyByValue(value: string): string | undefined {
    return Object.keys(JobTitles).find(key => JobTitles[key] === value);
  }
  getGeoLocationKeyByValue(value: string): string | undefined {
    return Object.keys(GeoLocation).find(key => GeoLocation[key] === value);
  }

  sortTable(column: string): void {
    switch (column) {
      case 'name':
      case 'employeeId':
      case 'jobTitle':
      case 'department':
      case 'carrierManagerName':
      case 'email':
        this.sortByColumn(column);
        break;
    }
  }

  private sortByColumn(column: string): void {
    const currentSort = this.getCurrentSortForColumn(column);

    if (currentSort.column === column) {
      currentSort.direction = currentSort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      currentSort.column = column;
      currentSort.direction = 'asc';
    }

    this.updateCurrentSortForColumn(column, currentSort);

    this.filteredUsers.sort((a, b) => {
      const aValue = a[column] ? a[column].toLowerCase() : null;
      const bValue = b[column] ? b[column].toLowerCase() : null;

      if (aValue === null && bValue === null) {
        return 0;
      } else if (aValue === null) {
        return 1;
      } else if (bValue === null) {
        return -1;
      } else {
        return currentSort.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    });
  }

  private getCurrentSortForColumn(column: string): { column: string, direction: 'asc' | 'desc' } {
    switch (column) {
      case 'name':
        return this.currentSortName;
      case 'employeeId':
        return this.currentSortEmployeeId;
      case 'jobTitle':
        return this.currentSortJobTitle;
      case 'department':
        return this.currentSortDepartment;
      default:
        return { column: '', direction: 'asc' };
    }
  }

  private updateCurrentSortForColumn(column: string, currentSort: { column: string, direction: 'asc' | 'desc' }): void {
    switch (column) {
      case 'name':
        this.currentSortName = currentSort;
        break;
      case 'employeeId':
        this.currentSortEmployeeId = currentSort;
        break;
      case 'jobTitle':
        this.currentSortJobTitle = currentSort;
        break;
      case 'department':
        this.currentSortDepartment = currentSort;
        break;
    }
  }
  exportToExcel(): void {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.height = "80%";
    dialogConfig.panelClass = "activate-employee"
    const dialogRef = this.dialog.open(ExportEmployeeDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => { });
    
    // const fileName = ExcelSheetName.ActiveEmployee;
    // const header = ['Name', 'Employee ID', 'Gender', 'DOB', 'Marital Status', 'Mobile number', 'Alternate number', 'Job Title', 'Capability', 'Career Manager', 'Email', 'Geo location', 'Base Location'];

    // const data = this.filteredUsers.map(user => ({
    //   'Name': user.name,
    //   'Employee ID': user.employeeId,
    //   'Gender': user.gender,
    //   'DOB': formatDate(user.dateOfBirth, 'yyyy-MM-dd', 'en-US'),
    //   'Marital Status': user.relationshipStatus,
    //   'Mobile number': user.mobileNumber,
    //   'Alternate number': user.alternateMobileNumber,
    //   'Job Title': JobTitles[user.jobTitle],
    //   'Capability': Departments[user.department],
    //   'Geo location': user.geoLocation,
    //   'Base Location': user.baseLocation,
    //   'Career Manager': user.carrierManagerName,
    //   'Email': user.email
    // }));

    // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header });
    // const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    // saveAs(blob, fileName);
  }
  getEnumValueByKey(type, key: string): any {
    return type[key];

  }
}